<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue'

const {
  offlineReady,
  needRefresh,
  updateServiceWorker,
} = useRegisterSW()

async function close() {
  offlineReady.value = false
  needRefresh.value = false
}
</script>

<template>
  <div
    v-if="offlineReady || needRefresh"
    class="pwa-toast fixed bottom-0 right-0 z-100 m-16px border border-#8885 rounded-4px border-solid bg-white p-12px text-left dark:bg-dark"
    role="alert"
  >
    <div class="mb-8px">
      <span v-if="offlineReady">
        应用程序已准备好离线工作
      </span>
      <span v-else>
        新版本可用，点击刷新按钮更新版本内容。
      </span>
    </div>
    <button v-if="needRefresh" class="mr-5px border border-#8885 rounded-2px border-solid px-10px py-3px outline-none" @click="updateServiceWorker()">
      刷新
    </button>
    <button class="mr-5px border border-#8885 rounded-2px border-solid px-10px py-3px outline-none" @click="close">
      好的
    </button>
  </div>
</template>

<style scoped>
.pwa-toast {
  box-shadow: 3px 4px 5px 0 #8885;
}
</style>
