import type { PaginationParams, R, R_Pagination } from '~/types/global'
import qs from 'query-string'
import { requestWithAdmin } from './AxiosInstance/admin'

export interface LoginData {
  username: string
  password: string
  code: string
  randomStr: string
}

export interface LoginRes {
  access_token: string
  active: boolean
  client_id: string
  expires_in: number
  license: string
  refresh_token: string
  scope: string
  token_type: string
  user_id: string
  username: string
}
const scope = 'server'
const basicAuth = `Basic ${window.btoa('pig:pig')}`
const basicAuthValue = useStorage('basicAuth', basicAuth, sessionStorage)

export function login(data: LoginData): Promise<LoginRes> {
  const { username, password, randomStr, code } = data
  const grant_type = 'password'
  const dataObj = qs.stringify({ username, password })
  return requestWithAdmin.request({
    url: '/auth/oauth/token',
    method: 'post',
    headers: {
      'isToken': false,
      'TENANT-ID': '1',
      'Authorization': basicAuthValue.value,
    },
    params: { randomStr, code, grant_type },
    data: dataObj,
  })
}

export function loginByMobile(mobile: string, code: string): Promise<LoginRes> {
  const grant_type = 'mobile'
  const basicAuth = `Basic ${window.btoa('app:app')}`
  return requestWithAdmin.request({
    url: '/auth/oauth/token',
    method: 'post',
    headers: {
      'isToken': false,
      'TENANT-ID': '1',
      'Authorization': basicAuth,
    },
    params: { mobile: `SMS@${mobile}`, code, grant_type, scope },
  })
}

export function loginTest(username: string, password: string): Promise<LoginRes> {
  return requestWithAdmin.request({
    url: '/auth/oauth/token',
    method: 'post',
    headers: {
      Authorization: 'Basic dGVzdDp0ZXN0',
    },
    data: new URLSearchParams({
      grant_type: 'password',
      scope: 'server',
      username,
      password,
    }),
  })
}

export function logout() {
  return requestWithAdmin.delete<null>('/auth/token/logout')
}

export interface UserParams extends Partial<UserRecord>, PaginationParams {}
export interface UserRecord {
  userId: string
  username: string
  name: string
  roleId: string
  roleCode: string
  roleName: string
  avatar: string
  phone: string
  password: string
  companyId: string
}

export interface UserQuery {
  phone: string
  name: string
  roleId: string
  companyId: string
}

export function initUserQueryForm(): UserQuery {
  return {
    phone: '',
    name: '',
    roleId: '',
    companyId: '',
  }
}

export function initUserForm(): UserRecord {
  return {
    userId: '',
    username: '',
    name: '',
    roleId: '',
    roleCode: '',
    roleName: '',
    avatar: '',
    phone: '',
    password: '',
    companyId: '',
  }
}

export function queryUserPage(params: UserParams): R_Pagination<UserRecord> {
  return requestWithAdmin.request({
    url: '/admin/user/page',
    method: 'get',
    params,
  })
}

export function deleteUser(id: string) {
  return requestWithAdmin.delete(`/admin/user/${id}`)
}

export function transferUser(id: string) {
  return requestWithAdmin.post(`/admin/user/transfer/${id}`)
}

export function postUser(data: UserRecord) {
  return requestWithAdmin.request({
    url: '/admin/user',
    method: 'post',
    data,
  })
}

export function putUser(data: UserRecord) {
  return requestWithAdmin.request({
    url: '/admin/user',
    method: 'put',
    data,
  })
}

export interface UserRegisterRecord {
  companyId: string
  username: string
  phone: string
  code: string
  password: string
  cpassword: string
}

export function registerUser(data: UserRegisterRecord) {
  return requestWithAdmin.request({
    url: '/admin/register/user',
    method: 'post',
    data,
  })
}

export function forgetUser(data: any) {
  return requestWithAdmin.request({
    url: '/admin/register/forgot/password',
    method: 'post',
    data,
  })
}

export function reviewUser(data: any): Promise<R<boolean>> {
  return requestWithAdmin.request({
    url: '/admin/user/to/examine',
    method: 'post',
    data,
  })
}
